<template>
  <base-layout>
    <h1>Победители</h1>
    <section class="winners">
          <div class="search">
            <div class="search-item icon">
              <input @keyup="getWinners" v-model="filter.prize"
                     placeholder="Приз" name="search_1" type="text">
            </div>
            <div class="search-item">
<!--              <input :type="'date'" :format="'DD.MM.YYYY'" placeholder="Дата" name="search_2" type="text">-->
              <date-picker v-model="filter.birthDate" :input-attr="{ id: 'birth-date' }" :type="'date'" :format="'DD.MM.YYYY'"
                           :placeholder="'Дата'" :editable="false" />
            </div>
            <div class="search-item icon">
              <input @keyup="getWinners" v-model="filter.phone" v-mask="'+7(###) ###-##-##'"
                     placeholder="Телефон" name="search_2" type="text">
            </div>
          </div>
        <table-db :header="winners.header" :rows="winners.rows" />

    </section>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout"
import TabsDb from "@/components/TabsDb"
import TableDb from "@/components/TableDb"
import { VueAgile } from 'vue-agile'
import {
  winnersOptions,
  carousel
} from '@/carousel/config'
import DatePicker from "vue2-datepicker";
import { getWinners } from "@/api";

export default {
  name: "PrizesPersonal",
  components: {
    //agile: VueAgile,
    BaseLayout,
    //TabsDb,
    TableDb,
    DatePicker,
  },

  data() {
    return {
      winnersOptions,
      carousel,
      filter: {
        prize: "",
        phone: "",
        birthDate: null
      },
      links: [
        {
          name: "Гарантированный мерч",
          href: "/winners/garant"
        },
      ],
      winners: {
        header: ["№", "Имя", "Дата розыгрыша", "Приз", "Номер телефона"],
        rows: []
      }
    }
  },

  methods: {
    getSvgImgUrl(image) {
      return require(`../../assets/svg/${image}`)
    },

    getPngImgUrl(image) {
      return require(`../../assets/img/${image}`)
    },

    async getWinners() {
      let phone = this.filter.phone

      phone = phone.replace("-", "")
          .replace("(", "")
          .replace(")", "")
          .replace("+", "")
          .replace(" ", "")

      const date = this.filter.birthDate !== null
          ? this.filter.birthDate.getFullYear() + "-" + (this.filter.birthDate.getMonth() + 1) + "-" + this.filter.birthDate.getDate()
          : null

      const response = await getWinners(this.filter.prize, phone, date)
      let counter = 0
      this.winners.rows = []

      response.forEach((item) => {
        const date = new Date(item.created_at)
        this.winners.rows.push({
          key: item.id,
          cols: [
            {
              key: 1,
              value: ++counter
            },
            {
              key: 2,
              value: item.name
            },
            {
              key: 3,
              value: date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
            },
            {
              key: 4,
              value: item.prize_name
            },
            {
              key: 5,
              value: item.phone
            }
          ]
        })
      })
    }
  },

  computed: {
    getDate() {
      return this.filter.birthDate
    }
  },

  watch: {
    getDate() {
      this.getWinners()
    }
  },

  created() {
    this.getWinners()
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

$input-border-color: #DBDBDB;
$input-bg-color: #FFFFFF;
$input-text-color: #666;

@media screen and (min-width: 993px) {
  .winners {
    &::before, &::after {
      content: "";
      position: absolute;
      display: block;
      background-size: cover;
    }

    &::before {
      width: 90px;
      height: 100px;
      top: 120px;
      left: 10%;
      //background-image: url("../../assets/svg/label-yellow-thunderbolt.svg");
    }

    &::after {
      width: 100px;
      height: 95px;
      top: 120px;
      right: 10%;
      //background-image: url("../../assets/svg/label-heart.svg");
    }
  }
}

a {
  text-decoration: none;
}

.wrapper {
  background-color: #fff;
}

h1, h2, h3 {
  text-align: center;
}

h1 {
  font-size: 60px;
  font-weight: 500;
  color: #3F3F3F;
}

.winners {
  margin: 20px 8% 20px 8%;
  background-size: contain;
  background-position: center;
  background-color: #F3F3F3;
  border-radius: 40px;
  padding: 25px;
}

.winners--gallery {
  text-align: center;

  img {
    width: 90%;
    margin: 0 2.5% 0 2.5%;
  }
}

.search {
  padding: 10px 0 10px 0;

  .search-item {
    width: 31.2%;
    margin: 0 1% 15px 1%;
    display: inline-block;
    position:relative;
  }

  .search-item.icon:after {
    position: absolute;
    top: 13px;
    right: 20px;
    content: url(../../assets/svg/search.svg);
  }
}

input {
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  padding-left: 30px;
  border: 2px solid #DBDBDB;
  background-color: #FFFFFF;
  font-family: "Montserrat";
  font-size: 20px;
  border-radius: 50px;
  color: #666;
  //text-transform: uppercase;
}


</style>

<style>
.agile__nav-button:hover {
  cursor: pointer;
}

.agile__dots {
  align-items: center;
  display: flex;
  list-style: none;
  padding: 0;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -100px;
}

.agile__dots {
  bottom: -201px;
}

.agile__dots {
  bottom: -155px;
}

.agile__dot {
  display: block;
  height: 10px;
  width: 10px;
  background-color: #3F3F3F;
  border-radius: 10px;
  transition: width 200ms;
  margin: 0 2.5px;
}

.agile__dot button {
  opacity: 0;
  height: 100%;
  width: 100%;
  color:transparent;
}

.agile__dot--current {
  width: 35px;
}

.agile__nav-button {
  border: none;
  background-color: transparent;
  position: relative;
  height: 50px;
  width: 50px;
  color: transparent;
  margin: 0 5px 0 5px;
}

.agile__nav-button:after {
  content: url('../../assets/svg/arrow-yellow.svg');
  position:absolute;
  left: 0;
  top: 0;
}

.agile__nav-button--next {
  transform: rotate(180deg);
}

.agile__actions {
  justify-content: space-between;
  position: absolute;
  width: 105%;
  bottom: 107px;
  left: -2.5%;
}



@media screen and (max-width: 1420px) {
  .tab-link {
    font-size: 16px !important;
  }

  .tabs-links {
    top: -57px !important;
  }
}


@media screen and (max-width: 992px) {

  .agile__actions {
    bottom: 23px;
    width: 115%;
    left: -27px;
  }

  .agile__dots {
    bottom: -50px;
  }

  .search-item {
    width: 100% !important;
  }

  h2 {
    font-size: 16px !important;
  }

  h1 {
    font-size: 24px !important;
  }

}

@media screen and (max-width: 992px) {
  .tab-link {
    padding: 15px;
    max-width: 32.5% !important;
    font-size: 12px !important;
    word-break: break-all;
  }

  .winners {
    margin: 20px 0% 20px 0% !important;
  }

  .mx-input {
    height: 60px;
    font-size: 21px;
  }
}

</style>