<template>
  <div>
    <table class="table">
      <thead v-if="header">
      <tr>
        <th v-for="item in header" :key="item">
          {{ item }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr></tr>
      <tr v-for="row in rows" :key="row.key">
        <td v-for="col in row.cols" :key="col.key">
          {{ col.value }}
        </td>
      </tr>
      </tbody>
    </table>
    <div class="table-mob">
      <div v-for="row in getRowsMob" :key="row.key"
          class="table-mob-block">
        <div v-for="subRow in row.cols" :key="subRow.key"
            class="table-mob-row">
            <div v-for="item in subRow.items" :key="item"
                class="table-mob-col">
              {{ item.value }}
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableDb",
  props: {
    header: {
      type: Array,
      required: true
    },
    rows: {
      type: Array,
      required: true
    }
  },

  computed: {
    getRowsMob() {
      let rows = []
      let itemCounter = 0

      this.rows.forEach((item) => {
        let subRows = []

        for (let counter = 0; counter < this.header.length; counter++) {
          subRows.push({
            key: counter + 1,
            items: [
              {
                key: 1,
                value: this.header[counter]
              },
              {
                key: 2,
                value: item.cols[counter].value
              }
            ]
          })
        }

        rows.push({
          key: ++itemCounter,
          cols: subRows
        })
      })

      return rows
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

$even-tr-bg: #D7D7D7;

.table {
  width: 100%;
  font-size: 20px;
  line-height: 50px;
  color: $black;
  text-align: center;
  border-collapse: collapse;
  border: none;

  thead {
    color: $white;

    tr {
      background-color: $purple;
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: $even-tr-bg;
    }
  }

  th, td {
    padding: 15px 30px 15px 30px;
  }

  td:first-child,
  th:first-child {
    border-radius: 70px 0 0 70px;
    -moz-border-radius: 70px 0 0 70px;
    -webkit-border-radius: 70px 0 0 70px;
  }

  td:last-child,
  th:last-child {
    border-radius: 0 70px 70px 0;
    -moz-border-radius: 0 70px 70px 0;
    -webkit-border-radius: 0 70px 70px 0;
  }
}

.table-mob {
  display: none;
  color: $black;

  .table-mob-block {
    margin: 10px 0 10px 0;
    padding: 10px;
    border-radius: 6px;

    .table-mob-row {
      .table-mob-col {
        display: inline-block;
        box-sizing: border-box;
        width: 50%;

        &:first-child {
          color: $gray;
        }
      }
    }

    &:nth-child(even) {
      background-color: #FFFFFF;
    }
  }
}

@media screen and (max-width: $lg) {
  .table {
    display: none;
  }

  .table-mob {
    display: block;
  }
}

@media screen and (max-width: 1420px) {
  .table {
    font-size: 16px;
  }
}
</style>